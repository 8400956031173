/**
 * Utility class for working with GUIDs (Globally Unique Identifiers).
 */
export default class GUIDUTIL {
    // Regular expression to validate GUID format
    private static readonly guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  
    /**
     * Generates a new GUID.
     * @returns {string} A new GUID in the format `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.
     */
    public static generate(): string {
      const s: string[] = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((parseInt(s[19], 16) & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
  
      const uuid = s.join("");
      return uuid;
    }
  
    /**
     * Validates if a given string is in the correct GUID format.
     * @param {string} guid - The GUID to validate.
     * @returns {boolean} True if the GUID is valid, false otherwise.
     */
    public static validate(guid: string | null): boolean {
        if(guid === null) {
            return false;
        }
      return this.guidRegex.test(guid);
    }
  
    /**
     * Converts a given GUID to uppercase.
     * @param {string} guid - The GUID to convert.
     * @returns {string} The uppercase version of the GUID.
     */
    public static toUpperCase(guid: string): string {
      return guid.toUpperCase();
    }
  
    /**
     * Converts a given GUID to lowercase.
     * @param {string} guid - The GUID to convert.
     * @returns {string} The lowercase version of the GUID.
     */
    public static toLowerCase(guid: string): string {
      return guid.toLowerCase();
    }
  
    /**
     * Formats a given string (assuming it's a valid GUID without hyphens) into the standard GUID format with hyphens.
     * @param {string} guid - The GUID to format.
     * @returns {string} The formatted GUID with hyphens.
     */
    public static formatWithHyphens(guid: string): string {
      const cleaned = guid.replace(/[^0-9a-f]/gi, '');
      return [
        cleaned.slice(0, 8),
        cleaned.slice(8, 12),
        cleaned.slice(12, 16),
        cleaned.slice(16, 20),
        cleaned.slice(20, 32)
      ].join('-');
    }
  
    /**
     * Removes hyphens from a given GUID.
     * @param {string} guid - The GUID from which to remove hyphens.
     * @returns {string} The GUID without hyphens.
     */
    public static removeHyphens(guid: string): string {
      return guid.replace(/-/g, '');
    }
  }
