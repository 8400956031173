import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContactBE } from 'src/app/classes/ContactBE';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { CompareCode } from 'src/app/util/SortCompare';
import { ContactDataService } from '../contact-data.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { NgModel, FormsModule } from '@angular/forms';
import { IgxDialogComponent, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxDatePickerComponent, IgxInputGroupModule, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';
import { BirthDayNumberValidatorDirective } from '../../../../directives/birth-day-number-validator.directive';
import { HeadingComponent } from '../../../UI/heading/heading.component';
import { CommonModule, NgIf } from '@angular/common';
import GUIDUTIL from 'src/app/util/GUID';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserRightTypeEnum, UserAreaEnum } from 'src/app/util/UserRightUtil';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
  standalone: true,
  imports: [NgIf, CommonModule, HeadingComponent, IgxInputGroupModule, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxDatePickerComponent, BirthDayNumberValidatorDirective]
})
export class ContactInformationComponent implements OnInit {
  @Input() hasEditRights: boolean = false;

  constructor(private sys: SystemCacheService, private dlxClient: DatalexClient, public combo: ComboSelectFunctionsService, public cData: ContactDataService, private userRights: UserRightsProviderService) {
  }

  @ViewChild('nationalIdentityNumberCtrl') nationalIdentityNumberCtrl!: NgModel;
  @ViewChild('nationalNumberErrorDialog') public nationalNumberErrorDialog!: IgxDialogComponent;
  @Output() nationalNumberValidity: EventEmitter<boolean> = new EventEmitter();

  _data!: ContactBE;
  @Input() set data(val: ContactBE) {
    this._data = val;
    this.initilizeComponent();
  }
  get data(): ContactBE {
    return this._data;
  }
  @Input() componentTitle!: string | null;


  isCompany!: boolean | null;
  contactSectorId!: string | null;
  contactNumber!: number | null;
  firstName!: string;
  lastName!: string;
  contactGender: boolean | null = null
  name!: string | null;
  name2!: string | null;
  pseudoName!: string | null;
  occupation!: string | null;
  title!: string | null;
  shortForm!: string | null;
  businessSectorId!: string | null;
  nationalIdentityNumber!: string | null;
  dateOfBirth!: string | null;
  organizationNumber!: string | null;
  organizationalStructureId!: string | null;
  publicCompany!: boolean | null;

  responsibleUserId!: string | null;

  inputBlurred = false;

  toLocalIsoString = toLocalIsoString;

  genders = [
    {
      code: "M",
      label: "Mann",
      value: true,
      id: 'gender_m'
    },
    {
      code: "K",
      label: "Kvinne",
      value: false,
      id: "gender_k"
    },
    {
      code: "IO",
      label: "Ikke oppgitt",
      value: null,
      id: 'gender_io'
    }
  ]

  selectedCountryCode!: string | null;

  ngOnInit(): void {

    // this.selectedCountryCode = this.cData.contactCountry;
    this.selectedCountryCode = this.data.getCountry()?.Code ?? null;

    this.dlxClient.GetBusinessSectors().subscribe({
      next: (res) => { this.cData.businessSectors = res },
    });


    try {
      this.cData.allEmployees = this.sys.allEmployees.sort(CompareCode);
    } catch (err) {
      this.sys.isReady.subscribe((sys) => {
        this.cData.allEmployees = sys.allEmployees.sort(CompareCode);
      })
    }


  }

  getGenderId(value: boolean | null) {
    return this.genders.filter(g => g.value === value)![0].id
  }
  getGenderValue(id: string) {
    return this.genders.filter(g => g.id === id)![0].value
  }

  fixNullOutputFromSelect(val: any) {
    this.log({ val })
    if (val === "" || val === null || val === undefined) {
      return val.oldSelection;
    }

    return val
  }

  initilizeComponent() {

    this.isCompany = this.data.getIsCompany();
    this.contactSectorId = this.data.getContactSectorId()!.toUpperCase();
    this.contactNumber = this.data.getNumber();
    this.firstName = this.data.getFirstName();
    this.lastName = this.data.getLastName();
    this.contactGender = this.data.getGender();
    this.name = this.data.getName();
    this.name2 = this.data.getName2();
    this.pseudoName = this.data.getPseudoName();
    this.occupation = this.data.getOccupation();
    this.title = this.data.getTitle();
    this.shortForm = this.data.getShortForm();
    this.businessSectorId = this.data.getBusinessSectorId();
    this.nationalIdentityNumber = this.data.getNationalIdentityNumber();
    this.dateOfBirth = this.data.getDateOfBirth() !== null ? new Date(this.data.getDateOfBirth()!).toDateString() : null
    this.organizationNumber = this.data.getOrganizationNumber();
    this.organizationalStructureId = this.data.getOrganizationalStructureId();
    this.publicCompany = this.data.getPublicCompany();

    if (this.data.getDateOfBirth()) {
      let date = new Date(this.data.getDateOfBirth()!)
      this.dateOfBirth = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    } else {
      this.dateOfBirth = null;
    }
    this.responsibleUserId = this.data.getResponsibleUserId();

    this.cData.componentInit();
  }

  // setCountry(ev: string) {
  //   const country = this.cData.countries.filter(i => i.CountryCode === ev)[0];
  //   this.dlxClient.GetCountryByCode(country.CountryCode).subscribe({
  //     next: (res) => {
  //       this.data.setCountry(res);
  //       this.data.setCountryId(res.Id);
  //     }
  //   })
  // }


  setCountry(ev: string) {
    console.log(ev);

    const country = this.cData.countries.find(i => i.Code === ev);
    if (country) {
      console.log(country);

      this.dlxClient.GetCountryByCode(country.Code).subscribe({
        next: (res) => {
          console.log(res);
          console.log(res.Code);

          this.data.setCountry(res);
          this.data.setCountryId(res.Id);
          //       this.cData.contactCountry = res.Code;
        }
      });
    }
  }



  organizationalStructureChange(id: string | null) {
    this.data.setOrganizationalStructureId(id);
  }

  businessSectorChange(id: string | null) {
    this.data.setBusinessSectorId(id);
  }

  log(e: any) {

  }

  public isNationalNumberValid(): boolean | undefined {
    if (!this.nationalIdentityNumberCtrl) return undefined;

    return !!this.nationalIdentityNumberCtrl.valid;
  }


  get clientResponsibleAccessHandler(): boolean {
    if (this.hasEditRights === false) {
      return true;
    }

    const validationResult = GUIDUTIL.validate(this.responsibleUserId);
    return this.userRights.checkAccess(validationResult ? UserAreaEnum.CONTACT_CHANGE_CLIENTRESPONSIBLE : UserAreaEnum.CONTACT_SET_CLIENTRESPONSIBLE).accessId.toUpperCase() === UserRightTypeEnum.NONE;
  }


  coworkerSectorId = 'b414c24c-ca80-4c32-bfff-47090de59c4d';

  get isCoWorker(): boolean {
    return this.data.getContactSectorId()?.toUpperCase() === this.coworkerSectorId.toUpperCase();
  }


  onCountryChange(code: string) {
    const country = this.cData.countries.find(i => i.Code === code);

    if (country) {
      this.dlxClient.GetCountryByCode(country.Code).subscribe({
        next: (res) => {
          console.log('res:', res);
          this.data.setCountry(res);
          this.data.setCountryId(res.Id);
          this.selectedCountryCode = res.Code;
          this.data.isChanged = true;
          this.cData.contactCountry = res.Code;
        }
      });
    }
  }



  onContactSectorChange(e: ISimpleComboSelectionChangingEventArgs): void {
    const { newSelection, newValue, oldValue } = e;
    const id = newSelection ? newSelection.Id : null;

    if (newValue === undefined) {
      this.contactSectorId = oldValue;
      e.cancel = true;
      return;
    }

    if (this.contactSectorId !== id) {
      this.contactSectorId = id;
      this.data.setContactSectorId(id);
    } else {
      e.cancel = true;
    }
  }
}

