import { ICaseLimitedBE, IContactAddressBE, IContactBE, IContactConnectionBE, IContactDistributionTypeBE, IContactNotesBE, IContactPaymentTermBE, IContactPhoneBE, ICountryBE, ICurrencyCodeBE, IUserLimitedBE } from "@datalex-software-as/datalex-client";
import { ContactSectorIdEnum } from "../util/ContactSectorUtil";

export class ContactBE {
    private Id: string;
    private ContactSectorId: string | null;
    private Name: string;
    private FirstName: string;
    private LastName: string;
    private Name2: string | null;
    private PseudoName: string | null;
    private Number: number | null;
    private OrganizationNumber: string | null;
    private NationalIdentityNumber: string | null;
    private BusinessSectorId: string | null;
    private OrganizationalStructureId: string | null;
    private Occupation: string | null;
    private Gender: boolean | null;
    private ShortForm: string | null;
    private Title: string | null;
    private OurCustomerNumber: string | null;
    private ResponsibleUserId: string | null;
    private CountryId: string | null;
    private BankAccountNumber: string | null;
    private CurrencyCodeId: string | null;
    private VATTypeId: string | null;
    private VatObligated: boolean | null;
    private eInvoiceContact: boolean | null;
    private eInvoiceId: string | null;
    private EmailAddress: string | null;
    private WebAddress: string | null;
    private PublicCompany: boolean | null;
    private IBANBankName: string | null;
    private IBANBankAddress: string | null;
    private IBANBankCountryId: string | null;
    private IBANBankState: string | null;
    private IBANAccountNumber: string | null;
    private IBANBankPostalAddressId: string | null;
    private IBANIsSwift: boolean | null;
    private IBANSwiftClearingCode: string | null;
    private DateOfBirth: string | null;
    private EHFInvoice: boolean | null;
    private EHFInvoiceType: string | null;
    private Supplier: boolean | null;
    private IsUser: boolean | null;
    private IsCompany: boolean | null;
    private AccountsId: string | null;
    private Timestamp: number[] | null;
    private Addresses: IContactAddressBE[] | null;
    private Phones: IContactPhoneBE[] | null;
    private ContactNotes: IContactNotesBE[] | null;
    private Cases: ICaseLimitedBE[] | null;
    private ContactDistributions: IContactDistributionTypeBE[] | null;
    private Connections: IContactConnectionBE[] | null;
    private ContactPaymentTerms: IContactPaymentTermBE[] | null;
    private ClientResponsible: IUserLimitedBE | null;
    private Country: ICountryBE | null;
    private CurrencyCode: ICurrencyCodeBE | null;
    private EmailAddressInvoice: string | null;
    private EmailInvoice: boolean | null;
    private InvoiceMerge: boolean | null;
    private Digipost: boolean | null;
    private DigipostInvoice: boolean | null;
    private OriginCode: string | null;
    private IsNew: boolean;
    private IsDeleted: boolean;
    private Origin: IContactBE;

    public isChanged: boolean = false;

    constructor(contact: IContactBE) {

        this.Id = contact.Id;
        this.ContactSectorId = contact.ContactSectorId;
        this.Name = contact.Name;
        this.FirstName = contact.FirstName;
        this.LastName = contact.LastName;
        this.Name2 = contact.Name2;
        this.PseudoName = contact.PseudoName;
        this.Number = contact.Number;
        this.OrganizationNumber = contact.OrganizationNumber;
        this.NationalIdentityNumber = contact.NationalIdentityNumber;
        this.BusinessSectorId = contact.BusinessSectorId;
        this.OrganizationalStructureId = contact.OrganizationalStructureId;
        this.Occupation = contact.Occupation;
        this.Gender = contact.Gender;
        this.ShortForm = contact.ShortForm;
        this.Title = contact.Title;
        this.OurCustomerNumber = contact.OurCustomerNumber;
        this.ResponsibleUserId = contact.ResponsibleUserId;
        this.CountryId = contact.CountryId;
        this.BankAccountNumber = contact.BankAccountNumber;
        this.CurrencyCodeId = contact.CurrencyCodeId;
        this.VATTypeId = contact.VATTypeId;
        this.VatObligated = contact.VatObligated;
        this.eInvoiceContact = contact.eInvoiceContact;
        this.eInvoiceId = contact.eInvoiceId;
        this.EmailAddress = contact.EmailAddress;
        this.WebAddress = contact.WebAddress;
        this.PublicCompany = contact.PublicCompany;
        this.IBANBankName = contact.IBANBankName;
        this.IBANBankAddress = contact.IBANBankAddress;
        this.IBANBankCountryId = contact.IBANBankCountryId;
        this.IBANBankState = contact.IBANBankState;
        this.IBANAccountNumber = contact.IBANAccountNumber;
        this.IBANBankPostalAddressId = contact.IBANBankPostalAddressId;
        this.IBANIsSwift = contact.IBANIsSwift;
        this.IBANSwiftClearingCode = contact.IBANSwiftClearingCode;
        this.DateOfBirth = contact.DateOfBirth;
        this.EHFInvoice = contact.EHFInvoice;
        this.EHFInvoiceType = contact.EHFInvoiceType;
        this.Supplier = contact.Supplier;
        this.IsUser = contact.IsUser;
        this.IsCompany = contact.IsCompany;
        this.AccountsId = contact.AccountsId;
        this.Timestamp = contact.Timestamp;
        this.Addresses = contact.Addresses;
        this.Phones = contact.Phones;
        this.ContactNotes = contact.ContactNotes;
        this.Cases = contact.Cases;
        this.ContactDistributions = contact.ContactDistributions;
        this.Connections = contact.Connections;
        this.ContactPaymentTerms = contact.ContactPaymentTerms;
        this.ClientResponsible = contact.ClientResponsible;
        this.Country = contact.Country;
        this.CurrencyCode = contact.CurrencyCode;
        this.EmailAddressInvoice = contact.EmailAddressInvoice;
        this.EmailInvoice = contact.EmailInvoice;
        this.InvoiceMerge = contact.InvoiceMerge;
        this.Digipost = contact.Digipost;
        this.DigipostInvoice = contact.DigipostInvoice;
        this.OriginCode = contact.OriginCode;
        this.IsNew = contact.IsNew;
        this.IsDeleted = contact.IsDeleted;
        this.Origin = contact;
    }

    public get(identifiers: string[]) {
        let items: Record<string, any> = {};
        for (const identifier of identifiers) {
            //@ts-ignore
            if (this[identifier]) {
                //@ts-ignore
                items[identifier] = this[identifier];
            } else {
                //console.error(`field ${identifier} has no value or does not exist on ContactExtended`);
            }

        }
        return items;
    }

    public getId() {
        return this.Id;
    }
    public getContactSectorId() {
        return this.ContactSectorId;
    }
    public getName() {
        return this.Name;
    }
    public getFirstName() {
        return this.FirstName;
    }
    public getLastName() {
        return this.LastName;
    }
    public getName2() {
        return this.Name2;
    }
    public getPseudoName() {
        return this.PseudoName;
    }
    public getNumber() {
        return this.Number;
    }
    public getOrganizationNumber() {
        return this.OrganizationNumber;
    }
    public getNationalIdentityNumber() {
        return this.NationalIdentityNumber;
    }
    public getBusinessSectorId() {
        return this.BusinessSectorId;
    }
    public getOrganizationalStructureId() {
        return this.OrganizationalStructureId;
    }
    public getOccupation() {
        return this.Occupation;
    }
    public getGender() {
        return this.Gender;
    }
    public getShortForm() {
        return this.ShortForm;
    }
    public getTitle() {
        return this.Title;
    }
    public getOurCustomerNumber() {
        return this.OurCustomerNumber;
    }
    public getResponsibleUserId() {
        return this.ResponsibleUserId;
    }
    public getCountryId() {
        return this.CountryId;
    }
    public getBankAccountNumber() {
        return this.BankAccountNumber;
    }
    public getCurrencyCodeId() {
        return this.CurrencyCodeId;
    }
    public getVATTypeId() {
        return this.VATTypeId;
    }
    public getVatObligated() {
        return this.VatObligated;
    }
    public geteInvoiceContact() {
        return this.eInvoiceContact;
    }
    public geteInvoiceId() {
        return this.eInvoiceId;
    }
    public getEmailAddress() {
        return this.EmailAddress;
    }
    public getWebAddress() {
        return this.WebAddress;
    }
    public getPublicCompany() {
        return this.PublicCompany;
    }
    public getIBANBankName() {
        return this.IBANBankName;
    }
    public getIBANBankAddress() {
        return this.IBANBankAddress;
    }
    public getIBANBankCountryId() {
        return this.IBANBankCountryId;
    }
    public getIBANBankState() {
        return this.IBANBankState;
    }
    public getIBANAccountNumber() {
        return this.IBANAccountNumber;
    }
    public getIBANBankPostalAddressId() {
        return this.IBANBankPostalAddressId;
    }
    public getIBANIsSwift() {
        return this.IBANIsSwift;
    }
    public getIBANSwiftClearingCode() {
        return this.IBANSwiftClearingCode;
    }
    public getDateOfBirth() {
        return this.DateOfBirth;
    }
    public getEHFInvoice() {
        return this.EHFInvoice;
    }
    public getEHFInvoiceType() {
        return this.EHFInvoiceType;
    }
    public getSupplier() {
        return this.Supplier;
    }
    public getIsUser() {
        return this.IsUser;
    }
    public getIsCompany() {
        return this.IsCompany;
    }
    public getAccountsId() {
        return this.AccountsId;
    }
    public getTimestamp() {
        return this.Timestamp;
    }
    public getAddresses() {
        return this.Addresses;
    }
    public getPhones() {
        return this.Phones;
    }
    public getContactNotes() {
        return this.ContactNotes;
    }
    public getCases() {
        return this.Cases;
    }
    public getContactDistributions() {
        return this.ContactDistributions;
    }
    public getConnections() {
        return this.Connections;
    }
    public getContactPaymentTerms() {
        return this.ContactPaymentTerms;
    }
    public getClientResponsible() {
        return this.ClientResponsible;
    }
    public getCountry() {
        return this.Country;
    }
    public getCurrencyCode() {
        return this.CurrencyCode;
    }
    public getEmailAddressInvoice() {
        return this.EmailAddressInvoice;
    }
    public getEmailInvoice() {
        return this.EmailInvoice;
    }
    public getInvoiceMerge() {
        return this.InvoiceMerge;
    }
    public getDigipost() {
        return this.Digipost;
    }
    public getDigipostInvoice() {
        return this.DigipostInvoice;
    }
    public getOriginCode() {
        return this.OriginCode;
    }
    public getIsNew() {
        return this.IsNew;
    }
    public getIsDeleted() {
        return this.IsDeleted;
    }

    public getContact(): IContactBE {
        return {
            Id: this.Id,
            ContactSectorId: this.ContactSectorId ?? null,
            Name: this.Name,
            FirstName: this.FirstName,
            LastName: this.LastName,
            Name2: this.Name2 ?? null,
            PseudoName: this.PseudoName ?? null,
            Number: this.Number ?? null,
            OrganizationNumber: this.OrganizationNumber ?? null,
            NationalIdentityNumber: this.NationalIdentityNumber ?? null,
            BusinessSectorId: this.BusinessSectorId ?? null,
            OrganizationalStructureId: this.OrganizationalStructureId ?? null,
            Occupation: this.Occupation ?? null,
            Gender: this.Gender ?? null,
            ShortForm: this.ShortForm ?? null,
            Title: this.Title ?? null,
            OurCustomerNumber: this.OurCustomerNumber ?? null,
            ResponsibleUserId: this.ResponsibleUserId ?? null,
            CountryId: this.CountryId ?? null,
            BankAccountNumber: this.BankAccountNumber ?? null,
            CurrencyCodeId: this.CurrencyCodeId ?? null,
            VATTypeId: this.VATTypeId ?? null,
            VatObligated: this.VatObligated ?? null,
            eInvoiceContact: this.eInvoiceContact ?? null,
            eInvoiceId: this.eInvoiceId ?? null,
            EmailAddress: this.EmailAddress ?? null,
            WebAddress: this.WebAddress ?? null,
            PublicCompany: this.PublicCompany ?? null,
            IBANBankName: this.IBANBankName ?? null,
            IBANBankAddress: this.IBANBankAddress ?? null,
            IBANBankCountryId: this.IBANBankCountryId ?? null,
            IBANBankState: this.IBANBankState ?? null,
            IBANAccountNumber: this.IBANAccountNumber ?? null,
            IBANBankPostalAddressId: this.IBANBankPostalAddressId ?? null,
            IBANIsSwift: this.IBANIsSwift ?? null,
            IBANSwiftClearingCode: this.IBANSwiftClearingCode ?? null,
            DateOfBirth: this.DateOfBirth ?? null,
            EHFInvoice: this.EHFInvoice ?? null,
            EHFInvoiceType: this.EHFInvoiceType ?? null,
            Supplier: this.Supplier ?? null,
            IsUser: this.IsUser ?? null,
            IsCompany: this.IsCompany ?? null,
            AccountsId: this.AccountsId ?? null,
            Timestamp: this.Timestamp ?? null,
            Addresses: this.Addresses ?? null,
            Phones: this.Phones ?? null,
            ContactNotes: this.ContactNotes ?? null,
            Cases: this.Cases ?? null,
            ContactDistributions: this.ContactDistributions ?? null,
            Connections: this.Connections ?? null,
            ContactPaymentTerms: this.ContactPaymentTerms ?? null,
            ClientResponsible: this.ClientResponsible ?? null,
            Country: this.Country ?? null,
            CurrencyCode: this.CurrencyCode ?? null,
            EmailAddressInvoice: this.EmailAddressInvoice ?? null,
            EmailInvoice: this.EmailInvoice ?? null,
            InvoiceMerge: this.InvoiceMerge ?? null,
            Digipost: this.Digipost ?? null,
            DigipostInvoice: this.DigipostInvoice ?? null,
            OriginCode: this.OriginCode ?? null,
            IsNew: this.IsNew ?? false,
            IsDeleted: this.IsDeleted ?? false,
        }
    }

    public setContactSectorId(value: string | null) {
        this.ContactSectorId = value;
        this.setIsCompany(value);
        this.isChanged = this.isEqual('ContactSectorId')
    }
    public setName(value?: string) {
        if (value) {
            this.Name = value;
            this.isChanged = this.isEqual('Name')
        } else {
            this.Name = `${this.getLastName()} ${this.getFirstName()}`;
        }
    }
    public setFirstName(value: string) {
        this.FirstName = value;
        this.setName()
        this.isChanged = this.isEqual('FirstName')
    }
    public setLastName(value: string) {
        this.LastName = value;
        this.setName()
        this.isChanged = this.isEqual('LastName')
    }
    public setName2(value: string | null) {
        this.Name2 = value;
        this.isChanged = this.isEqual('Name2')
    }
    public setPseudoName(value: string | null) {
        this.PseudoName = value;
        this.isChanged = this.isEqual('PseudoName')
    }
    public setNumber(value: number | null) {
        this.Number = value;
        this.isChanged = this.isEqual('Number')
    }
    public setOrganizationNumber(value: string | null) {
        this.OrganizationNumber = value;
        this.isChanged = this.isEqual('OrganizationNumber')
    }
    public setNationalIdentityNumber(value: string | null) {
        this.NationalIdentityNumber = value;
        this.isChanged = this.isEqual('NationalIdentityNumber')
    }
    public setBusinessSectorId(value: string | null) {
        this.BusinessSectorId = value;
        this.isChanged = this.isEqual('BusinessSectorId')
    }
    public setOrganizationalStructureId(value: string | null) {
        this.OrganizationalStructureId = value;
        this.isChanged = this.isEqual('OrganizationalStructureId')
    }
    public setOccupation(value: string | null) {
        this.Occupation = value;
        this.isChanged = this.isEqual('Occupation')
    }
    public setGender(value: boolean | null) {
        this.Gender = value;
        this.isChanged = this.isEqual('Gender')
    }
    public setShortForm(value: string | null) {
        this.ShortForm = value;
        this.isChanged = this.isEqual('ShortForm')
    }
    public setTitle(value: string | null) {
        this.Title = value;
        this.isChanged = this.isEqual('Title')
    }
    public setOurCustomerNumber(value: string | null) {
        this.OurCustomerNumber = value;
        this.isChanged = this.isEqual('OurCustomerNumber')
    }
    public setResponsibleUserId(value: string | null) {
        this.ResponsibleUserId = value;
        this.isChanged = this.isEqual('ResponsibleUserId')
    }
    public setCountryId(value: string | null) {
        this.CountryId = value;
        this.isChanged = this.isEqual('CountryId')
    }
    public setBankAccountNumber(value: string | null) {
        this.BankAccountNumber = value;
        this.isChanged = this.isEqual('BankAccountNumber')
    }
    public setCurrencyCodeId(value: string | null) {
        this.CurrencyCodeId = value;
        this.isChanged = this.isEqual('CurrencyCodeId')
    }
    public setVATTypeId(value: string | null) {
        this.VATTypeId = value;
        this.isChanged = this.isEqual('VATTypeId')
    }
    public setVatObligated(value: boolean | null) {
        this.VatObligated = value;
        this.isChanged = this.isEqual('VatObligated')
    }
    public seteInvoiceContact(value: boolean | null) {
        this.eInvoiceContact = value;
        this.isChanged = this.isEqual('eInvoiceContact')
    }
    public seteInvoiceId(value: string | null) {
        this.eInvoiceId = value;
        this.isChanged = this.isEqual('eInvoiceId')
    }
    public setEmailAddress(value: string | null) {
        this.EmailAddress = value;
        this.isChanged = this.isEqual('EmailAddress')
    }
    public setWebAddress(value: string | null) {
        this.WebAddress = value;
        this.isChanged = this.isEqual('WebAddress')
    }
    public setPublicCompany(value: any) {
        this.PublicCompany = value;
        this.isChanged = this.isEqual('PublicCompany')
    }
    public setIBANBankName(value: string | null) {
        this.IBANBankName = value;
        this.isChanged = this.isEqual('IBANBankName')
    }
    public setIBANBankAddress(value: string | null) {
        this.IBANBankAddress = value;
        this.isChanged = this.isEqual('IBANBankAddress')
    }
    public setIBANBankCountryId(value: string | null) {
        this.IBANBankCountryId = value;
        this.isChanged = this.isEqual('IBANBankCountryId')
    }
    public setIBANBankState(value: string | null) {
        this.IBANBankState = value;
        this.isChanged = this.isEqual('IBANBankState')
    }
    public setIBANAccountNumber(value: string | null) {
        this.IBANAccountNumber = value;
        this.isChanged = this.isEqual('IBANAccountNumber')
    }
    public setIBANBankPostalAddressId(value: string | null) {
        this.IBANBankPostalAddressId = value;
        this.isChanged = this.isEqual('IBANBankPostalAddressId')
    }
    public setIBANIsSwift(value: boolean | null) {
        this.IBANIsSwift = value;
        this.isChanged = this.isEqual('IBANIsSwift')
    }
    public setIBANSwiftClearingCode(value: string | null) {
        this.IBANSwiftClearingCode = value;
        this.isChanged = this.isEqual('IBANSwiftClearingCode')
    }
    public setDateOfBirth(value: string | null) {
        this.DateOfBirth = value;
        this.isChanged = this.isEqual('DateOfBirth')
    }
    public setEHFInvoice(value: boolean | null) {
        this.EHFInvoice = value;
        this.isChanged = this.isEqual('EHFInvoice')
    }
    public setEHFInvoiceType(value: string | null) {
        this.EHFInvoiceType = value;
        this.isChanged = this.isEqual('EHFInvoiceType')
    }
    public setSupplier(value: boolean | null) {
        this.Supplier = value;
        this.isChanged = this.isEqual('Supplier')
    }
    public setIsUser(value: boolean | null) {
        this.IsUser = value;
        this.isChanged = this.isEqual('IsUser')
    }
    public setAccountsId(value: string | null) {
        this.AccountsId = value;
        this.isChanged = this.isEqual('AccountsId')
    }
    public setTimestamp(value: number[] | null) {
        this.Timestamp = value;
        this.isChanged = this.isEqual('Timestamp')
    }
    public setAddresses(value: IContactAddressBE[] | null) {
        this.Addresses = value;
        this.isChanged = this.isEqual('Addresses')
    }
    public setPhones(value: IContactPhoneBE[] | null) {
        this.Phones = value;
        this.isChanged = this.isEqual('Phones')
    }
    public setContactNotes(value: IContactNotesBE[] | null) {
        this.ContactNotes = value;
        this.isChanged = this.isEqual('ContactNotes')
    }
    public setCases(value: ICaseLimitedBE[] | null) {
        this.Cases = value;
        this.isChanged = this.isEqual('Cases')
    }
    public setContactDistributions(value: IContactDistributionTypeBE[] | null) {
        this.ContactDistributions = value;
        this.isChanged = this.isEqual('ContactDistributions')
    }
    public setConnections(value: IContactConnectionBE[] | null) {
        this.Connections = value;
        this.isChanged = this.isEqual('Connections')
    }
    public setContactPaymentTerms(value: IContactPaymentTermBE[] | null) {
        this.ContactPaymentTerms = value;
        this.isChanged = this.isEqual('ContactPaymentTerms')
    }
    public setClientResponsible(value: IUserLimitedBE | null) {
        this.ClientResponsible = value;
        this.isChanged = this.isEqual('ClientResponsible')
    }
    public setCountry(value: ICountryBE | null) {
        this.Country = value;
        this.isChanged = this.isEqual('Country')
    }
    public setCurrencyCode(value: ICurrencyCodeBE | null) {
        this.CurrencyCode = value;
        this.isChanged = this.isEqual('CurrencyCode')
    }
    public setEmailAddressInvoice(value: string | null) {
        this.EmailAddressInvoice = value;
        this.isChanged = this.isEqual('EmailAddressInvoice')
    }
    public setEmailInvoice(value: boolean | null) {
        this.EmailInvoice = value;
        this.isChanged = this.isEqual('EmailInvoice')
    }
    public setInvoiceMerge(value: boolean | null) {
        this.InvoiceMerge = value;
        this.isChanged = this.isEqual('InvoiceMerge')
    }
    public setDigipost(value: boolean | null) {
        this.Digipost = value;
        this.isChanged = this.isEqual('Digipost')
    }
    public setDigipostInvoice(value: boolean | null) {
        this.DigipostInvoice = value;
        this.isChanged = this.isEqual('DigipostInvoice')
    }
    public setOriginCode(value: string | null) {
        this.OriginCode = value;
        this.isChanged = this.isEqual('OriginCode')
    }

    private setIsCompany(contactSectorId: string | null): void {
        switch (contactSectorId) {
            case ContactSectorIdEnum.P:
            case ContactSectorIdEnum.S:
                this.IsCompany = false;
                break;
            default:
                this.IsCompany = true;
                break;
        }
    }


    private isEqual(key: string) {
        //@ts-ignore
        const oldValue = this.Origin[key];
        //@ts-ignore
        const newValue = this[key];
        //console.table({ key, oldValue, newValue });

        if (key === "DateOfBirth" && newValue.includes("NaN")) {
            return false
        }


        if (key === 'Phones' || key === 'Addresses' || key === 'Country') {
            const oldBase64 = btoa(JSON.stringify(oldValue));
            const newBase64 = btoa(JSON.stringify(newValue));

            return !(oldBase64 === newBase64);

        }

        if (typeof (oldValue) === 'string' && typeof (newValue) === 'string' && key.includes("Id")) {
            return !((oldValue as string).toUpperCase() === (newValue as string).toLocaleUpperCase());
        }
        return !(oldValue === newValue)
    }

}