<div class="grid-button-row">
  <app-button-row-button [enabled]="allowedToCreateNewCase()" [icon]="'create_new_folder'"
    (click)="newCaseDialog.open()" [label]="'Ny sak'">
  </app-button-row-button>
  <app-button-row-button [enabled]="!!selectedCase && caseAccessLevel !== userRightTypeEnum.NONE" [icon]="'folder_open'"
    [label]="'Åpne sak'" (click)="goToCase()">
  </app-button-row-button>
</div>
<div class="case-grid-wrapper">
  <igx-grid igxPreventDocumentScroll #contactCasesGrid appGridCount [data]="data.getCases()"
    [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" width="100%" height="100%"
    [emptyGridMessage]="'Det er ikke opprettet noen saker'" emptyFilteredGridMessage="Filtrering gir ingen treff"
    (rowSelectionChanging)="onGridRowSelecetion($event)" [cellSelection]="'none'" [rowSelection]="'single'"
    (doubleClick)="onDoubleClick($event)" (contextMenu)="onRightClick($event)" [hideRowSelectors]="true" sortable="true"
    [allowFiltering]="true">

    <igx-column [width]="isDesktop ? '110px' : '75px'" field="Number" [header]="isDesktop ? 'Saksnummer' : 'Nr.'"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column field="Title" header="Tittel" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column width="80px" field="CaseHandler" header="SB" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column width="80px" field="CaseResponsible" header="SA" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="15%" field="CaseClient" header="Klient" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="15%" field="CaseAdversary" header="Motpart" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="100px" field="CaseStatusTypeName" header="Status" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="isDesktop" field="CaseRoleName" header="Roller" [dataType]="'string'" [sortable]="true"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    <igx-grid-footer />
  </igx-grid>

  <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="contactCasesGrid" [column]="column"></grid-filter-input>
  </ng-template>
</div>


<igx-dialog #newCaseDialog (opened)="focusInput()" (closed)="resetDialog();" [closeOnOutsideSelect]="false">

  <igx-dialog-title>
    <div class="dialog-title-container">
      <div class="dialog-title">Opprett ny sak på kontakt</div>
      <igx-icon family="material-symbols-outlined" (click)="onCancle(); newCaseDialog.close()">close</igx-icon>
    </div>
  </igx-dialog-title>

  <!-- *ngIf="!!allEmployees && !!caseSA" -->

  <div class="newCaseWrapper">
    <div class="simple-select-wrapper">
      <div class="flex-col">
        <label #responsibleLabel igxLabel
          class="simple-select-label simple-select-label--selected">Saksansvarlig:</label>
        <igx-simple-combo #responsible id="newCaseSA" [data]="sys.allEmployees" displayDensity="compact"
          [type]="'border'" (opening)="combo.comboOpening(responsibleLabel)"
          (closing)="combo.comboClosing(responsibleLabel, caseSA)" [displayKey]="'ContactName'" [valueKey]="'Id'"
          [(ngModel)]="caseSA">
        </igx-simple-combo>

        <igx-input-group *ngIf="manualCaseNumber" displayDensity="compact" type="border">
          <input igxInput name="newCaseNumber" type="number" [(ngModel)]="newCaseNumber" />
          <label igxLabel for="newCaseNumber">Saksnummer:</label>
        </igx-input-group>

        <igx-input-group displayDensity="compact" type="border">
          <input igxInput #newCaseTitleInput name="newCaseTitle" type="text" [(ngModel)]="newCaseTitle" />
          <label igxLabel for="newCaseTitle">Tittel:</label>
        </igx-input-group>

        <igx-input-group displayDensity="compact" type="border">
          <input igxInput name="contactName" type="text" [(ngModel)]="contactName" readonly />
          <label igxLabel for="contactName">Klient hovedaddresat:</label>
        </igx-input-group>

      </div>

    </div>
  </div>
  <div class="action-buttons_wrapper" igxDialogActions>
    <button igxButton [disabled]="!(newCaseTitle && caseSA)" (click)="getNewCase(true);"
      [ngClass]="(newCaseTitle && caseSA) ? 'btnEnabled' : 'btnDisabled'" igxButton="raised" igxRipple="white">LAGRE
      OG ÅPNE</button>
    <div style="display: flex; column-gap: 1rem;">
      <button igxButton [disabled]="!(newCaseTitle && caseSA)" (click)="getNewCase(false);"
        [ngClass]="(newCaseTitle && caseSA) ? 'btnEnabled' : 'btnDisabled'" igxButton="raised"
        igxRipple="white">LAGRE</button>
      <button igxButton igxButton="raised" igxButtonColor="white" [style.background]="'#575757'" igxRipple="white"
        (click)="onCancle(); newCaseDialog.close()">AVBRYT</button>
    </div>

  </div>
</igx-dialog>

<ng-template #newCaseTemplate>
</ng-template>

<ng-template #caseContactTemplate>
  <igx-grid igxPreventDocumentScroll #contactCasesGrid [data]="contactCasesData"
    [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" width="100%" height="100%"
    [emptyGridMessage]="'Det er ikke opprettet noen saker'" emptyFilteredGridMessage="Filtrering gir ingen treff"
    (rowSelectionChanging)="onGridRowSelecetion($event)" [cellSelection]="'none'" [rowSelection]="'single'"
    (doubleClick)="onDoubleClick($event)" (contextMenu)="onRightClick($event)" [hideRowSelectors]="true" sortable="true"
    [allowFiltering]="true">

    <igx-column [width]="isDesktop ? '110px' : '75px'" field="Number" [header]="isDesktop ? 'Saksnummer' : 'Nr.'"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column field="Title" header="Tittel" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column width="80px" field="CaseHandler" header="SB" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column width="80px" field="CaseResponsible" header="SA" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="15%" field="CaseClient" header="Klient" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="15%" field="CaseAdversary" header="Motpart" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="!isMobile" width="100px" field="CaseStatusTypeName" header="Status" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-column *ngIf="isDesktop" field="CaseRoleName" header="Roller" [dataType]="'string'" [sortable]="true"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  </igx-grid>

  <ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
    <grid-filter-input [grid]="contactCasesGrid" [column]="column"></grid-filter-input>
  </ng-template>
</ng-template>