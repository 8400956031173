<div class="flex-col contact-spacer">
  <div class="flex-col form-group">
    <strong>Telefon</strong>
    <div class="flex-row telefon-wrapper ">

      <div class=" simple-select-wrapper phone-select simple-select-disable-reset">
        <label #tlfTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Telefontype:</label>
        <igx-simple-combo [data]="phoneNumberTypes" displayDensity="compact"
          (opening)="combo.comboOpening(tlfTypeLabel)" (closing)="combo.comboClosing(tlfTypeLabel, currentPhoneTypeId)"
          [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="currentPhoneTypeId" [type]="'border'"
          (ngModelChange)="onPhoneTypeChange($event)" [disabled]="!hasEditRights">
        </igx-simple-combo>
      </div>

      <igx-input-group class="phone-input" displayDensity="compact" type="border">
        <input igxInput name="phoneNumber" type="number" [(ngModel)]="currentPhoneNumber"
          (ngModelChange)="onPhoneInputChange($event)" [disabled]="!hasEditRights" />
        <label igxLabel for="phoneNumber">Telefonnummer:</label>
      </igx-input-group>
    </div>
  </div>
  <div class="flex-col form-group emails-Wrapper">
    <strong>E-post og web</strong>

    <div class="email-container">
      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress" appEmailValidator
          #emailAddressCtrl="ngModel" (focus)="hideErrorMessage('emailAddress')"
          (blur)="showErrorMessageOnBlur('emailAddress')" (ngModelChange)="data.setEmailAddress($event)"
          [disabled]="!hasEditRights" />
        <label igxLabel for="emailAddress">E-post:</label>
      </igx-input-group>
      <div *ngIf="showEmailErrorMessage && (emailAddressCtrl.errors?.['emailInvalid'] ?? false)" class="error-message">
        * Ugyldig e-postadresse.
      </div>
    </div>
    <!-- <igx-input-group displayDensity="compact" type="border">
      <input igxInput name="emailAddress" type="email" [(ngModel)]="emailAddress"
        (ngModelChange)="data.setEmailAddress($event)" />
      <label igxLabel for="emailAddress">E-post:</label>
    </igx-input-group> -->
    <div class="invoiceEmail-container">
      <igx-input-group displayDensity="compact" type="border">
        <input igxInput name="invoiceEmailAddress" type="email" [(ngModel)]="invoiceEmailAddress" appEmailValidator
          (focus)="hideErrorMessage('emailAddressInvoice')" (blur)="showErrorMessageOnBlur('emailAddressInvoice')"
          #invoiceEmailAddressCtrl="ngModel" (ngModelChange)="data.setEmailAddressInvoice($event)"
          [disabled]="!hasEditRights" />
        <label igxLabel for="invoiceEmailAddress">E-post faktura:</label>
      </igx-input-group>
      <div *ngIf="showInvoiceEmailErrorMessage && (invoiceEmailAddressCtrl.errors?.['emailInvalid'] ?? false)"
        class="error-message">
        * Ugyldig e-postadresse.
      </div>
    </div>
    <!-- <igx-input-group displayDensity="compact" type="border">
      <input igxInput name="invoiceEmailAddress" type="email" [(ngModel)]="invoiceEmailAddress"
        (ngModelChange)="data.setEmailAddressInvoice($event)" />
      <label igxLabel for="invoiceEmailAddress">E-post faktura:</label>
    </igx-input-group> -->

    <igx-input-group displayDensity="compact" type="border">
      <input igxInput name="webAddress" type="text" [(ngModel)]="webAddress"
        (ngModelChange)="data.setWebAddress($event)" [disabled]="!hasEditRights" />
      <label igxLabel for="webAddress">Hjemmeside:</label>
    </igx-input-group>
  </div>
</div>
<div class="flex-col">
  <div class="flex-col form-group">
    <strong>Adresser</strong>
    <ng-content *ngIf="screen.width > 550; then addressSelectorButtons; else addressSelectorDropdown"></ng-content>
    <div *ngIf="countryBe">
      <div *ngIf="_activeType === 'post'">
        <dlx-address-input [lockable]="true" (notifyAddressChange)="setAddressObj($event, 'postAddressObj')"
          [existingAddress]="postAddressObj" [country]="countryBe" [addressType]="addressTypes.PostAddress">
        </dlx-address-input>
        <!-- [contactCountry]="data.getCountry()!.Code ? data.getCountry()!.Code : null" -->
      </div>
      <div *ngIf="_activeType === 'visit'">
        <dlx-address-input [lockable]="true" (notifyAddressChange)="setAddressObj($event, 'visitAddressObj')"
          [existingAddress]="visitAddressObj" [country]="countryBe" [addressType]="addressTypes.VisitAddress">
        </dlx-address-input>
      </div>
      <div *ngIf="_activeType === 'invoice'">
        <dlx-address-input [lockable]="true" (notifyAddressChange)="setAddressObj($event, 'invoiceAddressObj')"
          [existingAddress]="invoiceAddressObj" [country]="countryBe" [addressType]="addressTypes.InvoiceAddress">
        </dlx-address-input>
      </div>
      <div *ngIf="_activeType === 'registered'">
        <dlx-address-input [lockable]="true" (notifyAddressChange)="setAddressObj($event, 'registeredAddressObj')"
          [existingAddress]="registeredAddressObj" [country]="countryBe" [addressType]="addressTypes.RegisteredAddress">
        </dlx-address-input>
      </div>
    </div>
  </div>
  <div class="flex-col form-group">
    <strong>Faktura</strong>
    <div class="flex-col">
      <div class="check-box-group">
        <input type="checkbox" name="ehfRecipient" id="ehfRecipient" [(ngModel)]="ehfInvoice" [disabled]="true">
        <label for="ehfRecipient">EHF mottaker</label>
      </div>
      <div class="check-box-group m-top-s">
        <input (change)="checkBoxChange($event, 'setVatObligated')" type="checkbox" name="vatObligated"
          id="vatObligated" [(ngModel)]="vatObligated" [disabled]="!hasEditRights">
        <label for="vatObligated">Avgiftspliktig</label>
      </div>
      <div class="check-box-group m-top-s">
        <input (change)="checkBoxChange($event, 'setEmailInvoice')" type="checkbox" name="invoiceEmail"
          id="invoiceEmail" [(ngModel)]="emailInvoice" [indeterminate]="emailInvoiceIndetermined"
          [disabled]="!hasEditRights">
        <label for="invoiceDigipost">Send faktura som E-post</label>
      </div>
      <div class="check-box-group m-top-s">
        <input (change)="checkBoxChange($event, 'setDigipostInvoice')" type="checkbox" name="invoiceDigipost"
          id="invoiceDigipost" [(ngModel)]="digipostInvoice" [indeterminate]="digipostInvoiceIndetermined"
          [disabled]="!hasEditRights">
        <label for="invoiceDigipost">Send faktura med Digipost</label>
      </div>
    </div>
  </div>
</div>

<ng-template #addressSelectorButtons>
  <div class="contact-split-section m-right radio-row">
    <label for="post">
      <input #post (change)="addressTypeChange(post.id)" type="radio" name="addresstype" id="post" checked>
      <span>Postadresse</span>
    </label>

    <label for="visit">
      <input #visit (change)="addressTypeChange(visit.id)" type="radio" name="addresstype" id="visit">
      <span>Besøksadresse</span>
    </label>

    <label for="invoice">
      <input #invoice (change)="addressTypeChange(invoice.id)" type="radio" name="addresstype" id="invoice">
      <span>Fakturaadresse</span>
    </label>

    <label for="registered">
      <input #registered (change)="addressTypeChange(registered.id)" type="radio" name="addresstype" id="registered">
      <span>Folkeregisteradresse</span>
    </label>
  </div>
</ng-template>
<ng-template #addressSelectorDropdown>
  <div class="simple-select-wrapper simple-select-disable-reset">
    <label #addressTypeLabel igxLabel class="simple-select-label simple-select-label--selected">Adressetype</label>
    <igx-simple-combo [data]="addressDropdownData" displayDensity="compact" [type]="'border'"
      (opening)="combo.comboOpening(addressTypeLabel)" (closing)="combo.comboClosing(addressTypeLabel, _activeType)"
      [displayKey]="'Name'" [valueKey]="'Id'" [(ngModel)]="_activeType" (ngModelChange)="addressTypeChange($event)"
      [disabled]="!hasEditRights">
    </igx-simple-combo>
  </div>
</ng-template>