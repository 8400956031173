import { Component, Input, ElementRef, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGridCellEventArgs, IgxDialogComponent, IgxGridComponent, IgxColumnComponent, IgxGridFooterComponent, IgxFilterCellTemplateDirective, IgxDialogTitleDirective, IgxIconComponent, IgxLabelDirective, IgxSimpleComboComponent, IgxInputGroupComponent, IgxInputDirective, IgxDialogActionsDirective, IgxButtonDirective, IgxRippleDirective } from '@infragistics/igniteui-angular';
import { ContactBE } from 'src/app/classes/ContactBE';
import { DatalexClient, ICaseBE, ICaseContactBE, ICaseLimitedBE, IContactBE, IRoleTypeBE, IUserBE, IUserLimitedBE } from '@datalex-software-as/datalex-client';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { CaseNumberCounterType } from 'src/app/util/CaseNumberTypes';
import { RoleCode } from 'src/app/util/RoleTypeEnum';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { FormsModule } from '@angular/forms';
import { GridFilterInputComponent } from '../../../UI/grid-filter-input/grid-filter-input.component';
import { NgIf, NgClass } from '@angular/common';
import { GridCountDirective } from '../../../../directives/grid-count.directive';
import { ButtonRowButtonComponent } from '../../../UI/button-row-button/button-row-button.component';
import { DeviceService } from 'src/app/services/device.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import newActivity from 'src/app/classes/Activity/Actvity';
import { ActivityLogService } from 'src/app/components/UI/activity-log-overlay/activity-log.service';


@Component({
  selector: 'app-contact-cases',
  templateUrl: './contact-cases.component.html',
  styleUrls: ['./contact-cases.component.scss'],
  standalone: true,
  imports: [ButtonRowButtonComponent, IgxGridComponent, GridCountDirective, IgxColumnComponent, NgIf, IgxGridFooterComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxDialogComponent, IgxDialogTitleDirective, IgxIconComponent, IgxLabelDirective, IgxSimpleComboComponent, FormsModule, IgxInputGroupComponent, IgxInputDirective, IgxDialogActionsDirective, IgxButtonDirective, IgxRippleDirective, NgClass]
})
export class ContactCasesComponent implements OnInit, OnDestroy {
  @ViewChild('newCaseTitleInput') newCaseTitleInput!: ElementRef;
  @ViewChild('contactCasesGrid') contactCasesGrid!: IgxGridComponent;

  @ViewChild('newCaseDialog') newCaseDialog!: IgxDialogComponent;

  @Input() hasEditRights: boolean = false;
  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;
  isDesktopDevice!: boolean;

  private subscriptions = new Subscription();
  constructor(
    public sys: SystemCacheService,
    private router: Router,
    private dlxClient: DatalexClient,
    public screen: ScreenDimensionService,
    public gridMethods: GridMethodsService,
    public combo: ComboSelectFunctionsService,
    private userRights: UserRightsProviderService,
    public screenSize: ScreenSizeService,
    public deviceService: DeviceService,
    private als: ActivityLogService
  ) {
    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));
  }

  _data!: ContactBE;
  @Input() set data(val: ContactBE) {
    if (!val) return;
    this._data = val;
    try {
      this.refresh()
    } catch {
      this.sys.isReady.subscribe(() => {
        this.refresh();
      })
    }
  };

  get data() {
    return this._data;
  }

  userRightTypeEnum = UserRightTypeEnum;


  newCase: boolean = true;
  caseSelected: boolean = false;
  allEmployees!: IUserLimitedBE[];
  caseSA!: string;
  newCaseTitle: string = "";
  newCaseNumber!: number | null;
  manualCaseNumber: boolean = false;
  contactName!: string;
  selectedCase!: ICaseLimitedBE | null;
  caseAccessLevel: UserRightTypeEnum = UserRightTypeEnum.NONE;

  caseHandlerAndResponsibleList: { sa: string, sb: string }[] = [];


  public getEmployeeInitials(id: string | null) {
    if (id === null) return ""
    const employee = this.sys.allEmployees.filter(emp => {
      return emp.Id.toUpperCase() === id.toUpperCase()
    });

    return employee[0].Shortform
  }

  ngOnInit(): void {
    try {
      this.caseAccessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId;


    } catch (error) {
      this.sys.isReady.subscribe(() => {
        this.caseAccessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId;
      })
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  contactCasesData: ICaseLimitedBE[] = [];
  public refresh() {
    if (this === undefined) return;
    this.contactCasesData = this.data.getCases() ?? []
    // this.newCase = cases === null ? true : false;
    this.selectedCase = this.contactCasesData.length === 1 ? this.contactCasesData[0] : null;
    this.allEmployees = this.sys.allEmployees;
    this.updateCaseSA(null);
    if (this.data.getLastName() && this.data.getFirstName()) {
      this.contactName = `${this.data.getLastName()} ${this.data.getFirstName()}`;
    } else {
      this.contactName = this.data.getName();
    }

    let phones = this.data.getPhones()?.every(p => {
      return p.Number + p.PhoneType + ", "
    })
  }
  // allowedToCreateNewCase() {
  //   const accessLevel = this.userRights.checkAccess(UserAreaEnum.CASE_CREATE_NEW).accessId;
  //   if (accessLevel && accessLevel === UserRightTypeEnum.FULL) return true;
  //   return false;
  // }

  allowedToCreateNewCase() {
    const saksbehandlingAccess = this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId;
    if (saksbehandlingAccess === UserRightTypeEnum.LOOK) {
      return false;
    }

    const registrereNySakAccess = this.userRights.checkAccess(UserAreaEnum.CASE_CREATE_NEW).accessId;
    if (registrereNySakAccess === UserRightTypeEnum.FULL) {
      return true;
    }

    return false;
  }


  updateCaseSA(id: string | null) {
    this.caseSA = this.setCaseSA(id)
  }

  setCaseSA(id: string | null) {
    if (id !== null) return id;
    if (this.data.getClientResponsible()) return this.data.getClientResponsible()!.Id;
    return this.sys.loggedInUser.Id
  }


  // toggleNewCase() {
  //   this.newCase = !this.newCase;
  // }

  onCancle() {
    this.newCaseTitle = "";
    this.newCaseNumber = null;
    // this.newCase = false;
  }

  resetDialog() {
    this.newCaseTitle = '';
    this.caseSA = this.setCaseSA(null)
  }


  focusInput() {
    this.newCaseTitleInput.nativeElement.focus();
  }


  onDoubleClick(e: IGridCellEventArgs) {
    if (!this.selectedCase) return
    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId === UserRightTypeEnum.NONE) {
      const activity = newActivity({
        message: `Ingen tilgang saksbehandling, kontakt din administrator.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      alert("Ingen tilgang saksbehandling, kontakt din administrator.")

      return
    }

    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId === UserRightTypeEnum.OWN_CASES) {
      if (this.userRights.checkCaseAccessOwnCases({ sa: this.selectedCase.CaseResponsibleId!, sb: this.selectedCase.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
        this.router.navigate(['/case', e.cell.row.data.Id]);
      } else {
        const activity = newActivity({
          message: `Ingen tilgang, du har kun tilgang til egne saker.`,
          type: "failure",
          timestamp: new Date()
        })

        this.als.appendActivity(activity)
        alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
        return
      }

      return
    }
    this.router.navigate(['/case', e.cell.row.data.Id]);
  }

  onRightClick(e: IGridCellEventArgs) {
    e.event.preventDefault()
  }

  // onSaveNewCaseAndEnter(dialog: IgxDialogComponent) {
  //   this.getNewCase(true);
  // }

  // onSaveNewCase(dialog: IgxDialogComponent) {
  //   this.getNewCase(false);
  // }

  getNewCase(navigateToCase: boolean = false) {
    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId === UserRightTypeEnum.NONE) {
      const activity = newActivity({
        message: `Ingen tilgang saksbehandling, kontakt din administrator.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      alert("Ingen tilgang saksbehandling, kontakt din administrator.")

      return
    }

    const user = this.sys.allEmployees.find(i => i.Id === this.caseSA);
    if (!user) {
      const activity = newActivity({
        message: `Fant ikke medarbeider.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }

    this.dlxClient.GetUser(user.Id).subscribe({
      next: (user) => {
        this.createCase({ user, navigateToCase })
      }
    })


  }


  createCase({ user, navigateToCase }: { user: IUserBE, navigateToCase: boolean }) {
    if (user.AccountsId === null) {
      const activity = newActivity({
        message: `Fant ingen regnskap på valgt medarbeider.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }

    this.dlxClient.NewCase().subscribe({
      next: (newCasebe) => {
        newCasebe.Title = this.newCaseTitle!;
        newCasebe.AccountId = user.AccountsId!;
        newCasebe.Number = this.setNewCaseNumber();
        this.createCaseRoles(user.ContactId, newCasebe, navigateToCase)
      }
    })
  }
  createCaseRoles(contactId: string, casebe: ICaseBE, navigateToCase: boolean = false) {
    this.dlxClient.GetContactById(contactId).subscribe({
      next: (userContact) => {
        const temp_contact = new ContactBE(userContact);
        this.dlxClient.NewCaseContact().subscribe({
          next: (caseContact) => {
            const caseContactTemplateKH = JSON.parse(JSON.stringify(caseContact));
            const caseContactTemplateISA = JSON.parse(JSON.stringify(caseContact));
            let KH = this.sys.roleTypes!.filter(rt => rt.Id === RoleCode.KH)[0];
            let caseContactKH = this.populateNewCaseContact(casebe, caseContactTemplateKH, this.data.getContact(), KH);
            let ISA = this.sys.roleTypes!.filter(rt => rt.Id === RoleCode.ISA)[0];
            let caseContactISA = this.populateNewCaseContact(casebe, caseContactTemplateISA, temp_contact.getContact(), ISA);
            casebe.CaseContacts = [caseContactKH, caseContactISA];
            this.saveNewCase(casebe, navigateToCase);

          }
        })
      }
    })
  }

  saveNewCase(casebe: ICaseBE, navigateToCase: boolean = false) {
    this.dlxClient.SaveCase(casebe).subscribe({
      next: (res) => {
        if (navigateToCase) {
          this.router.navigate(['/case', res.Id])
        } else {
          this.updateContactCaseGrid(res.Id)
        }
      }
    })
  }

  updateContactCaseGrid(caseId: string) {
    console.log("contactCasesGrid", this.contactCasesGrid);

    this.dlxClient.GetCaseLimited(caseId).subscribe({
      next: (res) => {
        res.CaseRoleName = res.CaseRoleName === "" ? "Klient hovedadressat" : res.CaseRoleName;
        this.contactCasesGrid.addRow(res);
        this.newCaseDialog.close();
      },
    })
  }

  goToCase() {
    if (!this.selectedCase) return
    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName === 'NONE') {
      const activity = newActivity({
        message: `Ingen tilgang saksbehandling, kontakt din administrator.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      alert("Ingen tilgang saksbehandling, kontakt din administrator.")
      return
    }

    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName === 'OWN_CASES') {
      if (this.userRights.checkCaseAccessOwnCases({ sa: this.selectedCase.CaseResponsibleId!, sb: this.selectedCase.CaseHandlerId }, this.sys.loggedInUser.ContactId)) {
        this.router.navigate(['/case', this.selectedCase.Id]);
      } else {
        const activity = newActivity({
          message: `Ingen tilgang, du har kun tilgang til egne saker.`,
          type: "failure",
          timestamp: new Date()
        })

        this.als.appendActivity(activity)
        alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
        return
      }

      return
    }


    this.router.navigate(['/case', this.selectedCase.Id])
  }


  private populateNewCaseContact(casebe: ICaseBE, newCaseContact: ICaseContactBE, contact: IContactBE, roleType: IRoleTypeBE) {
    let newContact = newCaseContact;
    let phones = contact.Phones?.every(p => {
      return p.Number + p.PhoneType + ", "
    })
    newContact.AccountsId = contact.AccountsId!;
    //newContact.AllPhoneNumbers = contact.AllPhoneNumbers;
    newContact.CaseId = casebe.Id;
    newContact.ContactId = contact.Id;
    newContact.ContactNumber = contact.Number!;
    newContact.ContactSectorId = contact.ContactSectorId;
    newContact.ContactInitials = contact.ShortForm!;
    newContact.ContactName = contact.Name;
    newContact.RoleTypeId = roleType.Id;
    newContact.RoleTypeName = roleType.Name;
    newContact.RoleTypeCategoryId = roleType.RoleTypeCategoryId;
    newContact.RoleTypeCode = roleType.Code;
    newContact.IsNew = true;
    return newContact
  }

  onGridRowSelecetion(data: any) {
    this.selectedCase = data.newSelection[0];
  }

  setNewCaseNumber() {
    let caseCounterType = this.sys.loggedInUserAccount.CaseCounterFunctionId?.toLocaleUpperCase();
    return caseCounterType === CaseNumberCounterType.A.toLocaleUpperCase() ? 0 : this.newCaseNumber!
  }
  ChangingValue(e: any) {

  }
  onInput(e: any) {

  }
}
