import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatalexClient, IContactBE, IContactExtendedBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { GetContactSectorById } from 'src/app/util/ContactSectorUtil';
import { ContactDataService } from './contact-data.service';
import { ContactBE } from 'src/app/classes/ContactBE';
import { ContactSummaryComponent } from './contact-summary/contact-summary.component';
import { ContactCasesComponent } from './contact-cases/contact-cases.component';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CountryService } from 'src/app/services/country.service';
import { NgModel } from '@angular/forms';
import { IgxDialogComponent, IgxButtonDirective, IgxRippleDirective, IgxAccordionComponent, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxExpansionPanelTitleDirective, IgxExpansionPanelBodyComponent, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { HeadingComponent } from '../../UI/heading/heading.component';
import { TitleComponent } from '../../UI/title/title.component';
import { ContactContactinfoComponent } from './contact-contactinfo/contact-contactinfo.component';
import { NgIf } from '@angular/common';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  standalone: true,
  imports: [NgIf, IgxButtonDirective, IgxRippleDirective, IgxAccordionComponent, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxExpansionPanelTitleDirective, IgxExpansionPanelBodyComponent, ContactSummaryComponent, ContactInformationComponent, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, ContactCasesComponent, ContactContactinfoComponent, TitleComponent, HeadingComponent, IgxDialogComponent]
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('nationalNumberErrorDialog') public nationalNumberErrorDialog!: IgxDialogComponent;
  @ViewChild(ContactInformationComponent) contactInfoComponent!: ContactInformationComponent;

  contactId: string | null;
  contact!: IContactExtendedBE;
  contactSector: string = "";
  expanded: boolean = true;
  contactChanged: boolean = false;
  lastPageOfset: number = 0;
  loaded = false;

  locked: boolean = true;
  toggleLock() {
    this.locked = !this.locked;
  }

  contactName!: string;

  contactBe!: ContactBE;


  hasEditRights: boolean = false;

  getContactSectorById(id: string | null) {
    const contactSector = GetContactSectorById(id);
    if (!contactSector) return "";
    if (contactSector.Code === "S") return "Selvstendig næringsdrivende";

    return contactSector.Name;
  }

  constructor(private aRoute: ActivatedRoute, public screen: ScreenDimensionService, private countryService: CountryService, private dlxClient: DatalexClient, public contactData: ContactDataService, private sys: SystemCacheService, private rights: UserRightsProviderService) {
    this.contactId = this.aRoute.snapshot.paramMap.get('id') as string;
    this.contactData.setContact(this.contactId);

    try {
      const contactAccess = this.rights.checkAccess(UserAreaEnum.CONTACTS);
      this.hasEditRights = contactAccess.accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();
    } catch (error) {
      this.sys.isReady.subscribe(() => {
        const contactAccess = this.rights.checkAccess(UserAreaEnum.CONTACTS);
        this.hasEditRights = contactAccess.accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();
      })
    }

    this.locked = false //this.screen.width > 550 ? false : true;
  }

  @ViewChild(ContactSummaryComponent) contactSummary!: ContactSummaryComponent;
  @ViewChild(ContactCasesComponent) contactCases!: ContactCasesComponent;



  ngOnInit(): void {




    this.aRoute.data.subscribe({
      next: (data) => {
        this.contactBe = new ContactBE(data['contact']);
        this.setContactName();
        this.initializeComponent();
      },
    })

  }

  ngAfterViewInit(): void {

  }

  initializeComponent() {
    this.loaded = true;
  }

  changeExpand() {
    this.expanded = !this.expanded;
  }


  collapse() {
    this.expanded = false;
  }

  onContactInformationChange() {


  }

  onContactChange() {
  }

  onSave(event: Event) {
    event.stopPropagation();
    this.save();
  }


  save() {

    if (!this.hasEditRights) {
      window.alert('Du har ikke rettigheter til å redigere denne kontakten.');
      return;
    }
    if (this.contactInfoComponent.isNationalNumberValid() !== undefined) {
      if (this.contactInfoComponent.isNationalNumberValid() === false) {
        this.nationalNumberErrorDialog.open()
        return;
      }
    }

    console.log(this.contactBe.getContact());
    
    this.dlxClient.SaveContact(this.contactBe.getContact()).subscribe({
      next: (response) => {

        console.log(response);
        this.contactBe = new ContactBE(response);
        this.setContactName();
        if (this.contactSummary) {
          this.contactSummary.refresh();
        };
        this.contactCases.updateCaseSA(this.contactBe.getResponsibleUserId());
        // this.dlxClient.GetContactById(response.Id).subscribe({
        //   next: (contact) => {
        //     this.contactBe = new ContactBE(contact);
        //     this.setContactName();
        //     if (this.contactSummary) {
        //       this.contactSummary.refresh();
        //     };
        //     this.contactCases.updateCaseSA(this.contactBe.getResponsibleUserId());
        //   },
        // })
      },
      error: (err: HttpErrorResponse) => {
        if (err.error === "Record changed by another session") {
          this.loaded = false;
          this.dlxClient.GetContactById(this.contactId!).subscribe({
            next: (contact) => {
              this.contactBe = new ContactBE(contact);
              this.setContactName();
              this.initializeComponent();
            },
          })
        }
      }
    })
  }


  setContactName() {
    if (this.contactBe.getName() !== null) {
      console.log(this.contactBe.getName());

      this.contactName = this.contactBe.getName();
      console.log(this.contactName);

    }
    else {
      this.contactName = `${this.contactBe.getLastName()} ${this.contactBe.getFirstName()}`;
    }
  }

  handleNationalNumberValidity(isValid: boolean) {
    if (!isValid) {
      this.save();
    } else {
      this.nationalNumberErrorDialog.open()
    }
  }

  /*
    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
      if (this.screen.width > 550) {
        let pageoffset = window.pageYOffset
        if (pageoffset > this.lastPageOfset) {
          this.expanded = false;
        }
        this.lastPageOfset = pageoffset;
      } 
    }
  */
}
