import { Component, Input, OnInit } from '@angular/core';
import { ContactBE } from 'src/app/classes/ContactBE';
import { IContactAddressBE, IContactExtendedBE, IContactPhoneBE } from '@datalex-software-as/datalex-client';
import { AddressTypeArray } from 'src/app/util/AddressTypeUtil';
import { PhoneNumberTypesArray } from 'src/app/util/PhoneNumberTypeUtil';
import { HeadingComponent } from '../../../UI/heading/heading.component';
import { NgIf, NgFor } from '@angular/common';
import { IgxIconModule } from '@infragistics/igniteui-angular';
import { get } from 'http';

interface SummaryValuePair {
  name: string,
  value: string,
}

@Component({
  selector: 'app-contact-summary',
  templateUrl: './contact-summary.component.html',
  styleUrls: ['./contact-summary.component.scss'],
  standalone: true,
  imports: [NgIf, HeadingComponent, NgFor, IgxIconModule]
})
export class ContactSummaryComponent implements OnInit {
  constructor() {

  }

  _data!: ContactBE;

  @Input() set data(val: ContactBE) {
    this._data = val;
    this.refresh();
  }
  get data(): ContactBE {
    return this._data;
  }
  @Input() title!: string | null;
  @Input() hasEditRights: boolean = false;
  phoneNumbers!: SummaryValuePair[] | null;
  addresses!: SummaryValuePair[] | null;
  emailAddresses!: SummaryValuePair[] | null;

  ngOnInit(): void {
    this.refresh();
  }

  public refresh() {
    let { Phones, Addresses, EmailAddress, EmailAddressInvoice } = this.data.get(['Phones', 'Addresses', 'EmailAddress', 'EmailAddressInvoice']);
    this.extractSummary({ Phones, Addresses, EmailAddress, EmailAddressInvoice });
  }

  extractSummary({ Phones, Addresses, EmailAddress, EmailAddressInvoice }: any) {
    this.phoneNumbers = this.extractPhoneNumbers(Phones);
    this.addresses = this.extractAddresses(Addresses);
    this.emailAddresses = this.extractEmailAddresses({ EmailAddress, EmailAddressInvoice });
  }

  extractPhoneNumbers(phones: IContactPhoneBE[]): SummaryValuePair[] | null {
    if (phones.length === 0) return null
    return phones.map(phone => {
      return { name: PhoneNumberTypesArray.filter(item => item.Id === phone.PhoneTypeId)[0].Name, value: phone.Number }
    })
  }

  extractEmailAddresses({ EmailAddress, EmailAddressInvoice }: any): SummaryValuePair[] | null {
    let emails = [];
    if (EmailAddress) emails.push({ name: "Epost", value: EmailAddress });
    if (EmailAddressInvoice) emails.push({ name: "Faktura epost", value: EmailAddressInvoice });

    if (emails.length === 0) return null
    return emails
  }
  extractAddresses(addresses: IContactAddressBE[]): SummaryValuePair[] | null {
    if (addresses.length === 0) return null
    return addresses.map(address => {
      const postalAddress = address.PostalAddress ?? null;

      const postal = postalAddress ? `, ${postalAddress.PostCode} ${postalAddress.PostOffice}` : "";

      return {
        name: AddressTypeArray.filter(item => item.Id === address.AddressTypeId)[0].Name,
        value: address.Address + postal
      }
    })
  }

  copyText(value: string, event: Event) {
    const button = event.currentTarget as HTMLElement;

    // Copy the text to the clipboard
    navigator.clipboard.writeText(value).then(() => {
      // Find the icon and tooltip within the clicked button
      const icon = button.querySelector('.copy-icon');
      const tooltip = button.querySelector('.tooltip-text');

      if (icon && tooltip) {
        // Temporarily change the icon and tooltip text to indicate success
        icon.textContent = 'done';
        tooltip.textContent = 'kopiert';

        // Reset the icon and tooltip text after 2 seconds
        setTimeout(() => {
          icon.textContent = 'content_copy';
          tooltip.textContent = 'kopiere';
        }, 2000);
      }
    });
  }



}

